import * as React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../components/seo';
import Layout from '../../components/layout';
import Post from '../../components/post';
import MailChimp from '../../components/mailchimp';

const Blog = ({ data }) => (
  <Layout>
    <SEO
      title="Expert articles, blogs, and tutorials on Web Components, JavaScript, WordPress, and everything Web"
      desc="Expert knowledge for the Web Community from the Nieuwenhuis Web Development team."
      pathname="/blog"
    />
    <section>
      <div className="text-center mb-6">
        <h1>Blog</h1>
        <p>Expert guides, tutorials and blogs for the Web Community</p>
      </div>
    </section>
    <section>
      <div className="pt-6">
        {data.allMarkdownRemark.edges.map(post => {
          const {
            title,
            description,
            date,
            author,
            categories,
            featuredImage,
          } = post.node.frontmatter;
          const { slug } = post.node.fields;
          const { timeToRead } = post.node;

          return (
            <Post
              id={post.id}
              title={title}
              description={description}
              date={date}
              author={author}
              categories={categories}
              timeToRead={timeToRead}
              slug={slug}
              featuredImage={featuredImage}
            />
          );
        })}
      </div>
    </section>
    <MailChimp />
  </Layout>
);

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(formatString: "D MMMM Y")
            author
            categories
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          timeToRead
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default Blog;
