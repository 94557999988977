import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import CategoryPill from './category';

const PostHeader = ({ children }) => (
  <div className="p-6 bg-gray-100">{children}</div>
);

const PostBody = ({ children }) => <div className="px-6 py-4">{children}</div>;
const PostFooter = ({ children }) => (
  <div className="px-6 pt-4 pb-6">{children}</div>
);

const Post = ({
  id,
  title,
  description,
  date,
  author,
  categories,
  timeToRead,
  slug,
  featuredImage,
}) => (
  <article key={id}>
    <div className="rounded overflow-hidden mb-12">
      <PostHeader>
        <Link to={slug}>
          <GatsbyImage
            image={featuredImage?.childImageSharp.gatsbyImageData}
            alt={title}
          />
        </Link>
      </PostHeader>

      <PostBody>
        <Link to={slug}>
          <h2>{title}</h2>
        </Link>
        <p className="text-sm text-gray-600 mt-6">
          <span className="text-gray-900 leading-none">{author}</span>
          <span className="mx-1">・</span>
          <span>
            <time title={date} dateTime={new Date(date).toISOString()}>
              {date}
            </time>
          </span>
          <span className="mx-1">・</span>
          <span>{timeToRead} min read</span>
        </p>
        <p className="text-justify">{description}</p>
      </PostBody>

      <PostFooter>
        {categories.map(category => (
          <CategoryPill category={category} />
        ))}
      </PostFooter>
    </div>
  </article>
);

export default Post;
