import * as React from 'react';
import { Link } from 'gatsby';

const CategoryPill = ({ category }) => (
  <Link to={`/blog/category/${category.toLowerCase().replace(/\s/g, `-`)}`}>
    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2 mb-2">
      {category}
    </span>
  </Link>
);

export default CategoryPill;
